@import '../../color-variables.scss';

.grouped-detail{
	svg{
		width:12px;
	}
	a{
		color: $black-0;
		text-decoration: none;
	}
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXs.MuiDialog-paperFullWidth.css-twia2z-MuiPaper-root-MuiDialog-paper {
    background: $gray-20;
}
.plain-textfield input.MuiInputBase-input {
    background: #fff;
    height: 40px;
    padding: 0 14px;
}
.plain-textfield fieldset {
    border: 0;
    border-radius: 0;
}