.cart-table .MuiTableCell-root {
	border: 0 !important;
}

.cart-list .MuiListItem-root {
	padding: 4px 0;
}

.cart-list .MuiListItem-root span.title{
	opacity: .75;
}

.report-lists {
	overflow-x: scroll;
}

.report-lists .report-item {
	margin-right: 1rem;
	min-width: 25% !important;
}

.payment-card .StripeElement {
	margin-top: 10px;
}