@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~boxicons/css/boxicons.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;700&display=swap');
* {
	font-family: 'Poppins', sans-serif !important;
}


/** Layout Stylings */
@import './layouts/dashboard.scss';
@import './layouts/default.scss';

/** Utilities */
@import './utilities/svg-icons.scss';
@import './utilities/charts.scss';
@import './utilities/typography.scss';
@import './utilities/buttons.scss';

/** Page Stylings */
@import './pages/client-dashboard-home/reports.scss';
@import './pages/role-permission.scss';
@import './pages/open-response-cap.scss';
@import './pages/survey-scores.review.scss';
@import './pages/annual-trends.scss';
@import './pages/employee-verbatims/reports-header.scss';

/** Page Stylings -- admin*/
@import './pages/admin/users.scss';
@import './pages/admin/projects.scss';
@import './pages/admin/clients.scss';

*::placeholder {
	font-weight: 100 !important;
	color: #A8A9B4;
}

*::-webkit-scrollbar {
	height: 8px !important;
  width: 8px !important;               /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
	-webkit-box-shadow: none !important;
  /* background: transparent !important;  */
}

*::-webkit-scrollbar-thumb {
	background-color: #47525C; /* color of the scroll thumb */
	border-radius: 20px; /* roundness of the scroll thumb */
	border: 1px solid #47525C; /* creates padding around scroll thumb */
}

.section-loading-container{
	position: absolute;
	top: 50%;
	left:50%;
	z-index: 999;
}
.error {
	color: red;
}

a.MuiLink-root {
    color: $black-0;
    font-size: 14px;
    text-decoration: none;
}
a.MuiLink-root:hover {
    text-decoration: underline;
}

table .custom-header {
	background: linear-gradient(270deg, #EC413D 0%, #FF683A 100%);
}

.react-multiple-carousel__arrow--left {
	left: 0px
}
.react-multiple-carousel__arrow--right {
	right: 0px
}
.react-multiple-carousel__arrow {
	background-color: #000;
	min-width: 40px;
	min-height: 40px;
}

@media (min-width: 1440px) {
	.lg\:w-fit {
		max-width: calc(100% - 18rem);
	}
}
@media (min-width: 1536px) {
	.xl\:w-fit {
		max-width: calc(100% - 18rem);
	}
}
@media (min-width: 1920px) {
	.2xl\:w-fit {
		max-width: calc(100% - 18rem);
	}
}

.react-multi-carousel-dot button {
	border: 0;
	width: 8px;
	height: 8px;
	margin-right: 12px;
	background: gray;
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
	background: black;
}
.response-detail-page .table-section {
	overflow-x: scroll;
}
