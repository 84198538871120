@import '../../color-variables.scss';

.modal_add-user{

	>div>div{
		background : $gray-20;	
	}

	input{
		background : #fff
	}

	.MuiSelect-select.MuiInputBase-input{
		width: auto;
	}
}