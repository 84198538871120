/**
Note: The colors on this file must be the same that is declared in the tailwind.config.js
*/

$gray-0: #162630;
$red-0: #F2403B;
$red-10: #F3403C;
$black-0: #1D2124;
$black-10: #D3D3D3;
$gray-1: #777A7C;
$white: #f7f7f7;
$blue-0: #3F7CFB;
$green-0: #00A46A;
$yellow-0: #FFC955;
$gray-20: #F5F5F5;