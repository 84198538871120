#survey-score-reviews {

	.swiper-slide {
		position: relative !important;
	}

	.chart-card {
		.chart-carousel-card {

			background: white;
			width: 90%;

			.chart-container {
				height: 38vh;
				margin: 0 auto;

				svg {
					width: 100% !important;
				}
			}
		}
	}

	.vertical-table-header {
		@apply flex flex-row items-center space-x-3;
		white-space: nowrap;
		// display: block;
		margin-top: 10rem;
  	transform-origin: left;
		margin-left: 1rem;
		transform: rotate(-90deg);
		display: flex;
		float: left;
		position: absolute;
		top: 0;
		left: 12px;
		padding-top: 0px !important;
	}

	.vertical-header {
		position: relative;
	}

	.result-column {
		width: 8.57vw !important;
	}

	.question-header-column {
		width: 29vw;
		text-align: left;
	}

	.question-column {
		text-align: left;
		width: 30vw;
		padding-right: 50px;
	}

	.value-col {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

}