@import '../color-variables.scss';
.open-response-cap{
	.main-heading{
			display: flex;
			justify-content: space-between;
			margin-top: 30px;
			h5{
				font-size: 22px;
				font-weight: 600;
				color: $black-0;
			}
	}
	.list-item{
			list-style: none;
			margin-top: 20px;
			.list{
					list-style: none;
					// border-left: 4px solid $red-0;
					background-color: $white;
					margin-bottom: 10px;
					font-size: 14px;
					// &:nth-child(2) {
					// 		border-left: 4px solid #FFC955;
					// }
					// &:nth-child(3) {
					// 		border-left: 4px solid #00A46A;
					// }
					// &:nth-child(4) {
					// 		border-left: 4px solid #9C5EFB;
					// }
					// &:nth-child(5) {
					// 		border-left: 4px solid #3F7CFB;
					// }
					// &:nth-child(6) {
					// 		border-left: 4px solid #162630;
					// }
					// &:nth-child(7) {
					// 		border-left: 4px solid #9C5EFB;
					// }
					// &:nth-child(8) {
					// 		border-left: 4px solid #FFC955;
					// }
			}
	}
	.download-btn{
			border:1px solid #ccc;
			text-transform: capitalize;
			color: $gray-1;
			float: right;
			font-size: 14px;
			&:hover{
					border: 1px solid #ccc;
					color: $gray-1;
			}
	}
}