.svg-w-1 svg {
	@apply w-1;
}

.svg-w-2 svg {
	@apply w-2;
}

.svg-w-3 svg {
	@apply w-3;
}

.svg-w-4 svg {
	@apply w-4;
}

.svg-w-5 svg {
	@apply w-5;
}

.svg-w-6 svg {
	@apply w-6;
}

.svg-w-7 svg {
	@apply w-7;
}

.svg-w-8 svg {
	@apply w-8;
}

.svg-w-9 svg {
	@apply w-9;
}

.svg-w-10 svg {
	@apply w-10;
}

.svg-w-12 svg {
	@apply w-12;
}

.svg-w-14 svg {
	@apply w-14;
}

.svg-w-16 svg {
	@apply w-16;
}

.svg-w-32 svg {
	@apply w-32;
}

.svg-w-48 svg {
	@apply w-48;
}

.svg-w-64 svg {
	@apply w-64;
}