@import '../../color-variables.scss';

.projects-container, .admin_content-wrapper{
	font-size:14px;
	&>*{
		margin-bottom:40px!important;
	}	
}
.colored-item-list{
	.list-item{
		list-style: none;
		margin-bottom: 20px;
		.list{
			list-style: none;
			background-color: #fff;
			margin-bottom: 10px;
			font-size: 14px;
			height: 60px;
			font-weight:700;
		}
	}
}
.input.search-list {
    display: block;
    max-width: 400px;
    outline:0;
    width:100%;
    &::before{
    	content:none;
    }
    &:after{
    	border:0;
    }
    &:not(.Mui-focused):after{
    	content: '';
    	position: absolute;
    	right: -15px;
    	background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFjSURBVHgBrZPNUcJQEMd3X+JZCiDMIsHhZjrQEqAC0oIVgBUwViBUoFZgStCbozLsoJ7lTnjrvih+JEDE4X9J5u2+334+gB0J1xmIqAJ7ewRzqQD4zHzPsA2IqEULmPcAsa1GFoCZuhGABTRefzp+GEGZiJpRUA/fqhT2s4x+SM9OgnpzEhyEgxJIi5yju7DJTwPdrIKZ5Y8rB0VGL/yUbAJ5knZQIHaBV4K0J7EBfwgl0q7PAGVkTRoXQNUwKycpm8xSgpCIQFQAbStB1EnCfgHkpz7rJ/orCFMhLY8LIFeS7guXTeybhF20XlIAZTaRc13PQX5/8gqoEWtxNOXfi/kFeubxUGF3C+Nf5Ee7VK1x2NVsBiBwWkgyf+C2GlH0AiSexSyqNXgkIm2XiULOEKG3ENN55cfbtSAnl5HbEx3x8WfdrE/tWrO++rA3I4v2Mg/7lxysVm9M1rVhS9gOIHm9A6nCiGYzIptjAAAAAElFTkSuQmCC');
    	width: 18px!important;
	    height: 18px!important;
	    left: unset;
	    border: 0;
	    transform: translateY(-50%)!important;
    }
    input{
    	background-color:#fff;
	    height: 40px;
	    padding: 0 15px;
	    color:$black-0;
	    font-size:14px;
	    position:relative;
	    &::placeholder, &::-webkit-input-placeholder, &:-ms-input-placeholder{
	    	color:$black-0;
	    	opacity:1;
	    }
    }
}
button.btn_project-year{
    height: 40px;
    color:$black-0;
    border:1px solid $black-10;
}
button.btn_project-year.active{	
    background-color: $red-10;
    border-color:$red-10;
    color: #fff;
}
nav.pagination button.Mui-selected {
    color: #F3403C!important;
    background: none!important;
}