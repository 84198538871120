@import '../../color-variables.scss';


.reports-header {
    .filter-select{ 
        .MuiSvgIcon-root {
            fill: $white;
        }
        .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }}
    .filters-select{
        .MuiOutlinedInput-notchedOutline {
            border: none !important;
        }
    }
}