@import '../color-variables.scss';
.role-permission-section{
    padding: 10px 10px;
    .add-role-form{
        .form{
            display: flex;
            justify-content: space-between;
            .form-control{
                position: relative;
                background-color:#fff;
                border: transparent;
                .label{
                    position: absolute;
                    top:-5px;
                    font-size: 14px;
                }
            }
            .add-role-btn{
                margin-left: 15px;
                width: 200px;
                border: 1px solid #ccc;
                padding: 5px 5px;
                font-size: 13px;
                color: #1D2124;
            }
        }
    }
    .permission-list{
        margin-top: 25px;
        .heading{
            display: flex;
            justify-content: space-between;
            background-color: rgba(22, 38, 48, 0.05);
            padding: 10px 10px;
            p{
                font-size: 14px;
                color: $black-0;
            }
        }
        .data{
            background-color: #fff;
            .title{
                font-size: 14px;
                padding: 15px 10px;
                color: #9C5EFB;
            }
            .list{
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    font-size: 14px;
                    color: $black-0;
                    padding: 15px 10px;
                    text-transform: capitalize;
                }
                .css-julti5-MuiSwitch-root{
                    height: 55px;
                    width: 75px;
                    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
                        padding: 14px;
                        .css-jsexje-MuiSwitch-thumb{
                            height: 28px;
                            width: 28px;
                            background-color: #fff;
                        }
                    }
                    .css-1yjjitx-MuiSwitch-track{
                        border-radius: 15px;
                    }
                    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
                        background-color: #00A46A;
                        opacity: 1;
                    }
                }
            }
        }
    }
}