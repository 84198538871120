#default-navbar {
	@apply bg-gray-0 px-5 md:px-10 lg:px-10 xl:px-10 2xl:px-10 py-5 fixed top-0 w-full;
}

#default-navbar .default-navbar-content-wrapper {
	@apply flex flex-row container items-center;
	margin: 0 auto;
}

#default-navbar .title {
	@apply mr-10 text-3xl text-white font-bold;
}

#default-navbar .nav-item {
	@apply ml-8 text-base font-medium text-white hidden lg:block xl:block 2xl:block;
}

#default-layout-content-wrapper {
	@apply px-5 md:px-10 lg:px-10 xl:px-10 2xl:px-10;
}

#default-layout-content-wrapper #default-layout-content-container {
	@apply container pb-10 pt-28;
	margin: 0 auto;
}

