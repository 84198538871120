@import '../../color-variables.scss';

.dashboard-reports-section{
    margin-top: 50px;
    h5{
        font-size: 22px;
        color: $black-0;
        margin: 20px 0px;
        font-weight: 600;
    }
    .store-heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0px;
        h5{
            font-size: 22px;
            color: $black-0;
            margin: 20px 0px;
            font-weight: 600;
        }
        a{
            font-size: 16px;
            color: $red-0;
            text-decoration: none;
            position: relative;
            margin-right: 30px;
            &::after{
                position: absolute;
                content: '>';
                top: -4px;
                left: 130px;
                font-size: 22px;
                color: #1D2124;
            }
        }
    }
    .card-column{
        .grid-row{
            margin-top: 15px;
            .card-item{
                position: relative;
                padding: 20px 20px;
                &.report-section{
                    min-height: 200px;
                }
                min-height: 310px;
                h2{
                    padding: 25px 0px;
                    font-size: 15px;
                    font-weight: bold;
                    color: $black-0;
                }
                p{
                    font-size: 13px;
                }
                .link-tag{
                    position: absolute;
                    bottom: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    text-decoration: none;
                    color: $red-0;
                    display: block;
                    margin: 20px 0px;
                    &::after{
                        position: absolute;
                        content: '>';
                        top: -4px;
                        left: 100px;
                        font-size: 22px;
                    }
                }
                span{
                    font-size: 14px;
                    color: #1D2124;
                    display: block;
                    margin: 10px 0px;
                }
            }
        }
    }
}
.work_info_profile{
    .card-column{
        .grid-row{
            margin-top: 15px;
            .grid-column{
                .card-item{
                    padding: 20px 30px;
                    h2{
                        padding: 25px 0px;
                        font-size: 15px;
                        font-weight: bold;
                        color: $black-0;
                    }
                    p{
                        font-size: 14px;
                        line-height: 24px;
                        color: $gray-1;
                    }
                    .link-tag{
                        position: relative;
                        font-size: 16px;
                        font-weight: 500;
                        text-decoration: none;
                        color: $red-0;
                        display: block;
                        margin: 10px 0px;
                        &::after{
                            position: absolute;
                            content: '>';
                            top: -4px;
                            left: 100px;
                            font-size: 22px;
                            color: #1D2124;
                        }
                    }
                }
            }
        }
    }
}
ul.default-list{
    list-style: disc;
    padding-left: 20px;
    li{
        font-size: 14px;
    }
    span.view-report{
        cursor: pointer;
    }
    span.list-title{
        color: #8C60F3;
        font-weight: 700;
        line-height: 250%;
    }
}
