#side-menu-wrapper {
	@apply w-full bg-gray-0 overflow-hidden pr-1;
	height: 100vh;
}

#side-menu-header {
	@apply sm:p-3 md:p-3 lg:p-7 xl:p-8 2xl:p-8 flex flex-row justify-center items-center;
	height: 10vh;
}

#side-menu-content {
	@apply flex flex-col justify-center items-center overflow-auto;
	height: 90vh;

	#side-menu-list-wrapper {
		height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1vh;

		#side-menu-list {
			margin: auto;
		}
	}
	
	.menu-list-section-header * {
		font-size: 12px;
	}

	.menu-item-sub-child * {
		font-size: 14px;
	}

	.menu-item * {
		font-size: 14px;
	}
}

#content-layout-wrapper {
	@apply overflow-hidden;
	height: 100vh;

	#content-layout-header {
		@apply bg-white flex items-center justify-center;
		height: 8vh;
	}

	#dashboard-content-wrapper {
		@apply bg-gray-100;
		height: 86vh;
	}

	@media (max-width: 1440px) {
		#dashboard-content-wrapper {
			height: 93vh;
		}
	}

	#content-layout-footer {
		@apply
		flex items-center bg-white
		sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row;
		height: 6vh;
	}
}


.content-horizontal-padding {
	@apply px-3 md:px-10 lg:px-10 xl:px-10 2xl:px-10;
}

#navbar-user-avatar {
	height: 40px;
	width: 40px;
	background-color: #5648F4;
}

#year-dropdown {
	height: 40px;
}

#side-menu-drawer {
	@apply bg-gray-0;
	width: 35%;
	left: -35%;
	top: 0;
	bottom: 0;
	position: absolute;
	transition: all 0.2s ease;

	#side-menu-header {
		@apply pr-28;
	}

	#side-menu-drawer-closer {
		top: 0.5rem;
		right: 0.5rem;
		position: absolute;
		transition: all 0.5s ease;

		// i {
		// 	background: rgba(255, 255, 255, 0.5);
		// 	border-radius: 100%;
		// 	padding-left: 2px;
		// 	padding-bottom: 1px;
		// 	padding-top: 0.5px;
		// }
	}

}

@media (max-width: 425px) {
	#side-menu-drawer {
		width: 90% !important;
		left: -90% !important;
	}
}

#side-menu-drawer.show {
	left: 0% !important;
}