

.tooltip {
	opacity: 0;
	background: white;
	border: solid 1px;
	position: absolute;
	z-index: 1;
	top: 0;
	padding: 10px;
	pointer-events: none;
  }
  
  #labels-container {
	top: 50%;
	left: 50%;
	position: absolute;
	color: white
  }
  
  .label {
	position: absolute;
	border-radius: 10px 10px 0 10px;
	display: flex;
	justify-content: space-around;
	align-items: center;
  }